const NS = 'static:';
/**
 * i18n Keys for the module
 */
const staticCommonLabel = {
  /**
     * Static Label Keys for Grid Footer Bar - Attention :: Cleanup prequried
     */
  COMMON_LABEL_FILE_FORMAT: 'COMMON_LABEL_FILE_FORMAT',
  COMMON_LABEL_NUM_OF_ROWS: 'COMMON_LABEL_NUM_OF_ROWS',
  COMMON_LABEL_EXCEL_XLS: 'COMMON_LABEL_EXCEL_XLS',
  COMMON_LABEL_EXCEL_CSV: 'COMMON_LABEL_EXCEL_CSV',
  COMMON_LABEL_ALL_ROWS: 'COMMON_LABEL_ALL_ROWS',
  COMMON_LABEL_CURRENT_SCREEN: 'COMMON_LABEL_CURRENT_SCREEN',

  SHOWING_LABEL: 'COMMON_LABEL_SHOWING',
  OF_LABEL: 'COMMON_LABEL_OF',
  HYPHEN_LABEL: 'COMMON_LABEL_HYPHEN',
  SELECT_DROPDOWN_LABEL: 'COMMON_PLACEHOLDER_SELECT',
  RBAC_LABEL_WELCOME: 'RBAC_LABEL_WELCOME',
  LS_FOOTER_TEXT: 'LS_FOOTER_TEXT',
  LS_FOOTER_TEXT_ERROR: 'LS_FOOTER_TEXT',
  USER_NOT_FOUND: 'USER_NOT_FOUND',
  COMMON_LABEL_SELECT_OPTION: 'COMMON_LABEL_SELECT_OPTION',
  COMMON_LABEL_NA: 'COMMON_LABEL_NA',
  COMMON_LABEL_NO_TEMPLATE: 'COMMON_LABEL_NO_TEMPLATE',
  REPORT_OUTPUT_EXCEL: 'REPORT_OUTPUT_EXCEL',
  REPORT_OUTPUT_TEXT: 'REPORT_OUTPUT_TEXT',
  COMMON_LABEL_AUTOPRINT: 'COMMON_LABEL_AUTOPRINT',

  /**
     * Static Label Keys for FILTER SETTINGS ** START - Attention :: Cleanup required
     */
  FILTER_LABEL_SETTING: 'FILTER_LABEL_SETTING',
  FILTER_LABEL_NEW: 'FILTER_LABEL_NEW',
  FILTER_LABEL_MANAGEMENT: 'FILTER_LABEL_MANAGEMENT',
  FILTER_LABEL_CREATE_NEW: 'FILTER_LABEL_CREATE_NEW',
  FILTER_LABEL_MY_DEFAULT: 'FILTER_LABEL_MY_DEFAULT',
  FILTER_LABEL_USER_DEFAULT: 'FILTER_LABEL_USER_DEFAULT',
  FILTER_LABEL_SYSTEM_DEFAULT: 'FILTER_LABEL_SYSTEM_DEFAULT',
  FILTER_LABEL_CUSTOM_FILTER: 'FILTER_LABEL_CUSTOM_FILTER',
  FILTER_LABEL_CRITERIA: 'FILTER_LABEL_CRITERIA',
  FILTER_LABEL_CRITERIA_HELP: 'FILTER_LABEL_CRITERIA_HELP',
  FILTER_LABEL_COLUMN: 'FILTER_LABEL_COLUMN',
  FILTER_LABEL_OPERATOR: 'FILTER_LABEL_OPERATOR',
  FILTER_LABEL_VALUE: 'FILTER_LABEL_VALUE',
  FILTER_LABEL_SORT_ORDER: 'FILTER_LABEL_SORT_ORDER',
  FILTER_LABEL_SORT_ORDER_HELP: 'FILTER_LABEL_SORT_ORDER_HELP',
  FILTER_LABEL_GROUP_BY: 'FILTER_LABEL_GROUP_BY',
  FILTER_LABEL_GROUP_BY_HELP: 'FILTER_LABEL_GROUP_BY_HELP',
  FILTER_LABEL_DISTINCT: 'FILTER_LABEL_DISTINCT',
  FILTER_LABEL_DISTINCT_HELP: 'FILTER_LABEL_DISTINCT_HELP',
  FILTER_LABEL_HELP: 'FILTER_LABEL_HELP',
  FILTER_LABEL_VISIBILITY: 'FILTER_LABEL_VISIBILITY',
  FILTER_LABEL_VISIBILITY_HELP: 'FILTER_LABEL_VISIBILITY_HELP',
  FILTER_LABEL_AVAIL_COLUMNS: 'FILTER_LABEL_AVAIL_COLUMNS',
  FILTER_LABEL_SELECTED_COLUMNS: 'FILTER_LABEL_SELECTED_COLUMNS',
  FILTER_LABEL_ASCENDING: 'FILTER_LABEL_ASCENDING',
  FILTER_LABEL_DESCENDING: 'FILTER_LABEL_DESCENDING',
  FILTER_LABEL_PRIVATE: 'FILTER_LABEL_PRIVATE',
  FILTER_LABEL_SAVE: 'FILTER_LABEL_SAVE',
  FILTER_LABEL_SAVE_AS: 'FILTER_LABEL_SAVE_AS',
  FILTER_LABEL_NAME: 'FILTER_LABEL_NAME',
  FILTER_LABEL_SHARE_FILTER: 'FILTER_LABEL_SHARE_FILTER',
  FILTER_LABEL_MARK_USER_DEFAULT: 'FILTER_LABEL_MARK_USER_DEFAULT',
  FILTER_DEFAULT_CONFIRMATION: 'FILTER_DEFAULT_CONFIRMATION',
  FILTER_DUPLICATE_COL_SELECTED_TITLE: 'FILTER_DUPLICATE_COL_SELECTED_TITLE',
  FILTER_DUPLICATE_COL_SELECTED_MSG: 'FILTER_DUPLICATE_COL_SELECTED_MSG',
  FILTER_VISIBILITY_NO_COLS_SELECTED_TITLE: 'FILTER_VISIBILITY_NO_COLS_SELECTED_TITLE',
  FILTER_VISIBILITY_NO_COLS_SELECTED_MSG: 'FILTER_VISIBILITY_NO_COLS_SELECTED_MSG',
  FILTER_SAVE_SUCCESS: 'FILTER_SAVE_SUCCESS',
  FILTER_DELETE_CONFIRMATION: 'FILTER_DELETE_CONFIRMATION',
  FILTER_SHARED_DELETE_CONFIRMATION: 'FILTER_SHARED_DELETE_CONFIRMATION',
  FILTER_LABEL_SEARCH_HELP: 'FILTER_LABEL_SEARCH_HELP',
  FILTER_LABEL_CUSTOM_FILTERS: 'FILTER_LABEL_CUSTOM_FILTERS',
  FILTER_MESSGAE_SEARCH_TO_VIEW: 'FILTER_MESSGAE_SEARCH_TO_VIEW',
  FILTER_DELETE_SUCCESS: 'FILTER_DELETE_SUCCESS',
  FILTER_VISIBILITY_BETWEEN_OPS_SELECTED_MSG: 'FILTER_VISIBILITY_BETWEEN_OPS_SELECTED_MSG',
  // SYSTEM_DEFAULT_FILTER_TITLE: `${LOCAL_RESOURCE_NS}system.default.filter.title`,
  // SORT_ORDER_LABEL: `${LOCAL_RESOURCE_NS}sort.order.label`,
  COMMON_LIST_SHOW_PAGE: `${NS}cmn.list.show.page`,
  COMMON_LIST_SHOW_RECORD_DISPLAY: `${NS}cmn.list.record.display`,
  COMMON_LIST_SHOW_RESET: `${NS}cmn.list.reset`,
  COMMON_LIST_SHOW_ADD: 'COMMON_BUTTON_ADD',
  COMMON_LIST_SHOW_EXPORT: 'COMMON_BUTTON_EXPORT',
  COMMON_NO_RECORD_FOUND: 'COMMON_NO_RECORD_FOUND',
  /**
     * Static Label Keys for FILTER SETTINGS ** END - Cleanup required
     */
  /**
     * Common MSG_ID to be used across pages - START
     */
  REQFLD: 'REQFLD',
  COMMON_PLACEHOLDER_SELECT: 'COMMON_PLACEHOLDER_SELECT',
  COMMON_PLACEHOLDER_SELECTED: 'COMMON_PLACEHOLDER_SELECTED',
  COMMON_LABEL_OPTIONAL: 'COMMON_LABEL_OPTIONAL',
  COMMON_BUTTON_SAVE: 'COMMON_BUTTON_SAVE',
  COMMON_BUTTON_SAVE_AS: 'COMMON_BUTTON_SAVE_AS',
  COMMON_BUTTON_SEARCH: 'COMMON_BUTTON_SEARCH',
  COMMON_BUTTON_CANCEL: 'COMMON_BUTTON_CANCEL',
  COMMON_BUTTON_DELETE: 'COMMON_BUTTON_DELETE',
  COMMON_LABEL_ACTIONS: 'COMMON_LABEL_ACTIONS',
  COMMON_BUTTON_EDIT: 'COMMON_BUTTON_EDIT',
  COMMON_BUTTON_RESET_FILTER: 'COMMON_BUTTON_RESET_FILTER',
  COMMON_BUTTON_RESET: 'COMMON_BUTTON_RESET',
  COMMON_LABEL_PROCESS: 'COMMON_LABEL_PROCESS',
  COMMON_BUTTON_ADD: 'COMMON_BUTTON_ADD',
  COMMON_BUTTON_LEAVE: 'COMMON_BUTTON_LEAVE',
  COMMON_BUTTON_RETURNTOPAGE: 'COMMON_BUTTON_RETURNTOPAGE',
  COMMON_BUTTON_EXPORT: 'COMMON_BUTTON_EXPORT',
  COMMON_LABEL_PAGESIZE_PART1: 'COMMON_LABEL_PAGESIZE_PART1',
  COMMON_LABEL_PAGESIZE_PART2: 'COMMON_LABEL_PAGESIZE_PART2',
  COMMON_BUTTON_BACK: 'COMMON_BUTTON_BACK',
  COMMON_BUTTON_SAVE_AS_DEFAULT: 'COMMON_BUTTON_SAVE_AS_DEFAULT',
  COMMON_BUTTON_MAKE_DEFAULT: 'COMMON_BUTTON_MAKE_DEFAULT',
  COMMON_INVALID_FORMAT: 'COMMON_INVALID_FORMAT',
  COMMON_NOT_SPECIFIED: 'COMMON_NOT_SPECIFIED',
  COMMON_LABEL_DATATYPE: 'COMMON_LABEL_DATATYPE',
  COMMON_LABEL_PERSONALIZE: 'COMMON_LABEL_PERSONALIZE',
  COMMON_LABEL_PAYMETHOD: 'COMMON_LABEL_PAYMETHOD',
  COMMON_LABEL_GENERAL: 'COMMON_LABEL_GENERAL',
  COMMON_LABEL_CURRENCY: 'COMMON_LABEL_CURRENCY',
  COMMON_LABEL_AGENTSTATUS: 'COMMON_LABEL_AGENTSTATUS',
  ERRDUP: 'ERRDUP',
  ERRSFND: 'ERRSFND',
  COMMON_NO_REC_FOUND: 'COMMON_NO_REC_FOUND',
  COMMON_BUTTON_NO: 'COMMON_BUTTON_NO',
  COMMON_BUTTON_YES: 'COMMON_BUTTON_YES',
  COMMON_LABEL_SHOWING: 'COMMON_LABEL_SHOWING',
  COMMON_LABEL_OVERRIDE: 'COMMON_LABEL_OVERRIDE',
  COMMON_LABEL_OF: 'COMMON_LABEL_OF',
  COMMON_LABEL_OF_IN_SHOWING_PAGE_RANGE: 'COMMON_LABEL_OF_IN_SHOWING_PAGE_RANGE',
  COMMON_LABEL_HYPHEN: 'COMMON_LABEL_HYPHEN',
  COMMON_LABEL_LANGUAGE: 'COMMON_LABEL_LANGUAGE',
  COMMON_LABEL_NAME: 'COMMON_LABEL_NAME',
  COMMON_LABEL_DESCRIPTION: 'COMMON_LABEL_DESCRIPTION',
  COMMON_LABEL_IMPORTANT_INFO: 'COMMON_LABEL_IMPORTANT_INFO',
  COMMON_EXCEPTION_GENERIC: 'COMMON_EXCEPTION_GENERIC',
  GENERIC_NOTIFICATION: `${NS}GENERIC.NOTIFICATION`,
  COMMON_LABEL_OWNER: 'COMMON_LABEL_OWNER',
  COMMON_LABEL_SHARED_WITH: 'COMMON_LABEL_SHARED_WITH',
  COMMON_LABEL_CREATED_ON: 'COMMON_LABEL_CREATED_ON',
  COMMON_LABEL_LAST_MODIFIED_ON: 'COMMON_LABEL_LAST_MODIFIED_ON',
  COMMON_LABEL_LAST_MODIFIED_BY: 'COMMON_LABEL_LAST_MODIFIED_BY',
  COMMON_LABEL_LAST_MODIFIED_DATE: 'COMMON_LABEL_LAST_MODIFIED_DATE',
  COMMON_LABEL_LAST_USED_ON: 'COMMON_LABEL_LAST_USED_ON',
  COMMON_LABEL_LOAD: 'COMMON_LABEL_LOAD',
  COMMON_LABEL_ROLE: 'COMMON_LABEL_ROLE',
  COMMON_LABEL_SHARED: 'COMMON_LABEL_SHARED',
  COMMON_BUTTON_OK: 'COMMON_BUTTON_OK',
  COMMON_LABEL_VALUE: 'COMMON_LABEL_VALUE',
  COMMON_DATE_FORMAT_MM_DD_YYYY: 'COMMON_DATE_FORMAT_MM_DD_YYYY',
  COMMON_DATE_FORMAT_DD_MM_YYYY: 'COMMON_DATE_FORMAT_DD_MM_YYYY',
  COMMON_MSG_NO_DATA_AVAILABLE: 'COMMON_MSG_NO_DATA_AVAILABLE',
  PD_VIEW_ERROR: 'PD_VIEW_ERROR',
  PD_VIEW_ERROR_CURRENT: 'PD_VIEW_ERROR_CURRENT',
  COMMON_LABEL_PARENTVAL: 'COMMON_LABEL_PARENTVAL',
  COMMON_LABEL_PRODUCT: 'COMMON_LABEL_PRODUCT',
  COMMON_LABEL_DIVISION: 'COMMON_LABEL_DIVISION',
  COMMON_LABEL_LOB: 'COMMON_LABEL_LOB',
  COMMON_LABEL_SHOW_PRIVATE: 'COMMON_LABEL_SHOW_PRIVATE',
  COMMON_LABEL_LEVEL: 'COMMON_LABEL_LEVEL',
  COMMON_LABEL_ID: 'COMMON_LABEL_ID',
  COMMON_LABEL_IDENTITY: 'COMMON_LABEL_IDENTITY',
  COMMON_LABEL_ALL: 'COMMON_LABEL_ALL',
  COMMON_BUTTON_SIGNOUT: 'COMMON_BUTTON_SIGNOUT',
  COMMON_BUTTON_SIGNIN: 'COMMON_BUTTON_SIGNIN',
  COMMON_LABEL_LEGAL: 'COMMON_LABEL_LEGAL',
  COMMON_LABEL_ACCESSIBILITY: 'COMMON_LABEL_ACCESSIBILITY',
  COMMON_LABEL_PRIVACY_POLICY: 'COMMON_LABEL_PRIVACY_POLICY',
  COMMON_LABEL_STATUS: 'COMMON_LABEL_STATUS',
  COMMON_LABEL_STATUS_REASON: 'COMMON_LABEL_STATUS_REASON',
  COMMON_LABEL_STATUS_CHANGE_REASON: 'COMMON_LABEL_STATUS_CHANGE_REASON',
  COMMON_LABEL_AGENTNAME: 'COMMON_LABEL_AGENTNAME',
  COMMON_LABEL_SECGRP: 'COMMON_LABEL_SECGRP',
  COMMON_LABEL_INT_SECGRP: 'COMMON_LABEL_INT_SECGRP',
  COMMON_LABEL_EXT_SECGRP: 'COMMON_LABEL_EXT_SECGRP',
  COMMON_SYSTEM_SESSION_EXPIRED: 'COMMON_SYSTEM_SESSION_EXPIRED',
  COMMON_SYSTEM_SIGNOUT_SUCCESS: 'COMMON_SYSTEM_SIGNOUT_SUCCESS',
  COMMON_SYSTEM_SIGNIN_REQUEST: 'COMMON_SYSTEM_SIGNIN_REQUEST',
  COMMON_LABEL_SESSION_EXPIRY: 'COMMON_LABEL_SESSION_EXPIRY',
  COMMON_SESSION_EXPIRE_MSG: 'COMMON_SESSION_EXPIRE_MSG',
  COMMON_SESSION_EXPIRE_TIMEOUT: 'COMMON_SESSION_EXPIRE_TIMEOUT',
  COMMON_BUTTON_STAY_SIGNIN: 'COMMON_BUTTON_STAY_SIGNIN',
  COMMON_UNSAVED_WARNING: 'COMMON_UNSAVED_WARNING',
  LABEL_LEGAL_ERR: `${NS}LABEL.LEGAL.ERR`,
  ERROR_LABEL: `${NS}ERROR.LABEL`,
  LABEL_ACCESSIBILITY_ERR: `${NS}LABEL.ACCESSIBILITY.ERR`,
  LABEL_PRIVACY_POLICY_ERR: `${NS}LABEL.PRIVACY.POLICY.ERR`,
  COMMON_LABEL_STRUCTTYPE: 'COMMON_LABEL_STRUCTTYPE',
  COMMON_LABEL_NEXTSTRUCTTYPE: 'COMMON_LABEL_NEXTSTRUCTTYPE',
  COMMON_LABEL_SHAREPERCENT: 'COMMON_LABEL_SHAREPERCENT',
  COMMON_LABEL_CHAINED: 'COMMON_LABEL_CHAINED',
  COMMON_LABEL_AFFILIATE: 'COMMON_LABEL_AFFILIATE',
  COMMON_LABEL_APPROVER: 'COMMON_LABEL_APPROVER',
  COMMON_LABEL_COMMTYPE: 'COMMON_LABEL_COMMTYPE',
  COMMON_BUTTON_CONTINUE: 'COMMON_BUTTON_CONTINUE',
  COMMON_LABEL_COMMSUBTYPE: 'COMMON_LABEL_COMMSUBTYPE',
  COMMON_LABEL_TRANSACTIONID: 'COMMON_LABEL_TRANSACTIONID',
  COMMON_BUTTON_SKIP: 'COMMON_BUTTON_SKIP',
  COMMON_BUTTON_SAVE_AND_CONTINUE: 'COMMON_BUTTON_SAVE_AND_CONTINUE',
  COMMON_BUTTON_CLOSE: 'COMMON_BUTTON_CLOSE',
  COMMON_LABEL_MAXAMOUNT: 'COMMON_LABEL_MAXAMOUNT',
  COMMON_LABEL_SHOWLESS: 'COMMON_LABEL_SHOWLESS',
  COMMON_LABEL_SHOWMORE: 'COMMON_LABEL_SHOWMORE',
  COMMON_LABEL_SEL_ALL_OPTS_APPLY: 'COMMON_LABEL_SEL_ALL_OPTS_APPLY',
  COMMON_LABEL_CORPORATION: 'COMMON_LABEL_CORPORATION',
  COMMON_LABEL_TEMPLEVEL: 'COMMON_LABEL_TEMPLEVEL',
  COMMON_LABEL_LOCATION: 'COMMON_LABEL_LOCATION',
  COMMON_LABEL_ALTOFFICE: 'COMMON_LABEL_ALTOFFICE',
  COMMON_LABEL_DISTRIBUTOR: 'COMMON_LABEL_DISTRIBUTOR',
  COMMON_LABEL_REGION: 'COMMON_LABEL_REGION',
  COMMON_LABEL_CHANNEL: 'COMMON_LABEL_CHANNEL',
  COMMON_LABEL_COMPANY: 'COMMON_LABEL_COMPANY',
  COMMON_LABEL_ADVISOR_CODE: 'COMMON_LABEL_ADVISOR_CODE',
  COMMON_LABEL_ADVISOR_NAME: 'COMMON_LABEL_ADVISOR_NAME',
  COMMON_LABEL_RATEKEY: 'COMMON_LABEL_RATEKEY',
  COMMON_LABEL_RATE: 'COMMON_LABEL_RATE',
  COMMON_LABEL_GICTERM: 'COMMON_LABEL_GICTERM',
  COMMON_LABEL_TO_DATE: 'COMMON_LABEL_TO_DATE',
  COMMON_LABEL_STARTDATE: 'COMMON_LABEL_STARTDATE',
  COMMON_LABEL_ENDDATE: 'COMMON_LABEL_ENDDATE',
  COMMON_HEADER_DOWNLOAD_CENTRE: 'COMMON_HEADER_DOWNLOAD_CENTRE',
  COMMON_FILE_AUTODELETE_MSG: 'COMMON_FILE_AUTODELETE_MSG',
  COMMON_LAST_REFRESH_MSG: 'COMMON_LAST_REFRESH_MSG',
  COMMON_BUTTON_REFRESH: 'COMMON_BUTTON_REFRESH',
  COMMON_LABEL_FILENAME: 'COMMON_LABEL_FILENAME',
  COMMON_LABEL_REQUESTDATE: 'COMMON_LABEL_REQUESTDATE',
  COMMON_LABEL_DATETIME: 'COMMON_LABEL_DATETIME',
  COMMON_LABEL_INPROGRESS: 'COMMON_LABEL_INPROGRESS',
  COMMON_LABEL_BATCH_PROCESSING: 'COMMON_LABEL_BATCH_PROCESSING',
  COMMON_LABEL_HOMEPAGE: 'COMMON_LABEL_HOMEPAGE',
  COMMON_LABEL_STATUS_CHGDATE: 'COMMON_LABEL_STATUS_CHGDATE',
  COMMON_LABEL_REPORTING_NAME: 'COMMON_LABEL_REPORTING_NAME',
  COMMON_LABEL_CHEQUE_NAME: 'COMMON_LABEL_CHEQUE_NAME',
  COMMON_LABEL_CONTEST_NAME: 'COMMON_LABEL_CONTEST_NAME',
  COMMON_LABEL_DESIGNATION: 'COMMON_LABEL_DESIGNATION',
  COMMON_LABEL_COUNTRY: 'COMMON_LABEL_COUNTRY',
  COMMON_LABEL_CITY: 'COMMON_LABEL_CITY',
  COMMON_LABEL_POSTAL_CODE: 'COMMON_LABEL_POSTAL_CODE',
  COMMON_LABEL_ADDR_TYPE: 'COMMON_LABEL_ADDR_TYPE',
  COMMON_LABEL_ADDR_SUBTYPE: 'COMMON_LABEL_ADDR_SUBTYPE',
  COMMON_LABEL_FAX_NUM: 'COMMON_LABEL_FAX_NUM',
  COMMON_LABEL_NOTAPPICABLE: 'COMMON_LABEL_NOTAPPICABLE',
  COMMON_LABEL_SUM_RECORD_COUNT: 'COMMON_LABEL_SUM_RECORD_COUNT',
  COMMON_LABEL_AVG_OF: 'COMMON_LABEL_AVG_OF',
  COMMON_LABEL_SUM_OF: 'COMMON_LABEL_SUM_OF',
  COMMON_LABEL_MIN_OF: 'COMMON_LABEL_MIN_OF',
  COMMON_LABEL_MAX_OF: 'COMMON_LABEL_MAX_OF',
  COMMON_LABEL_ADDRESS: 'COMMON_LABEL_ADDRESS',
  COMMON_LABEL_SYSTEMDATE: 'COMMON_LABEL_SYSTEMDATE',
  COMMON_CLB_LABEL_ACNTHOLDER: 'COMMON_CLB_LABEL_ACNTHOLDER',
  COMMON_CLB_LABEL_BANK: 'COMMON_CLB_LABEL_BANK',
  COMMON_CLB_LABEL_BRANCHNUM: 'COMMON_CLB_LABEL_BRANCHNUM',
  COMMON_CLB_LABEL_ACNTTYPE: 'COMMON_CLB_LABEL_ACNTTYPE',
  COMMON_CLB_LABEL_ACNTNUM: 'COMMON_CLB_LABEL_ACNTNUM',
  COMMON_LABEL_ACNTHOLDER: 'COMMON_LABEL_ACNTHOLDER',
  COMMON_LABEL_BANK: 'COMMON_LABEL_BANK',
  COMMON_LABEL_BRANCHNUM: 'COMMON_LABEL_BRANCHNUM',
  COMMON_LABEL_ACNTTYPE: 'COMMON_LABEL_ACNTTYPE',
  COMMON_LABEL_ACNTNUM: 'COMMON_LABEL_ACNTNUM',
  PD_ADD_ERROR: `${NS}PD.ADD.ERROR`,
  COMMON_LABEL_ACTIVE: 'COMMON_LABEL_ACTIVE',
  COMMON_LABEL_DEFAULT: 'COMMON_LABEL_DEFAULT',
  COMMON_LABEL_DEFAULT_LEVEL: 'COMMON_LABEL_DEFAULT_LEVEL',
  COMMON_LABEL_TYPE: 'COMMON_LABEL_TYPE',
  COMMON_LABEL_FORMAT: 'COMMON_LABEL_FORMAT',
  COMMON_BUTTON_EXISTING: 'COMMON_BUTTON_EXISTING',
  COMMON_BUTTON_NEW: 'COMMON_BUTTON_NEW',
  COMMON_LABEL_AMOUNT: 'COMMON_LABEL_AMOUNT',
  COMMON_LABEL_TRANSFER_ID: 'COMMON_LABEL_TRANSFER_ID',
  COMMON_LABEL_DAYS: 'COMMON_LABEL_DAYS',
  COMMON_LABEL_PRIORITY: 'COMMON_LABEL_PRIORITY',
  COMMON_LABEL_TRANSACTION: 'COMMON_LABEL_TRANSACTION',
  COMMON_LABEL_TRANS_ADVISOR_NAME: 'COMMON_LABEL_TRANS_ADVISOR_NAME',
  CM_DE_LABEL_METHOD: 'CM_DE_LABEL_METHOD',
  CM_DE_LABEL_CUMULATIVE: 'CM_DE_LABEL_CUMULATIVE',
  COMMON_LABEL_FREQUENCY: 'COMMON_LABEL_FREQUENCY',
  COMMON_LABEL_CODE: 'COMMON_LABEL_CODE',
  COMMON_LABEL_ADVISORCODE: 'COMMON_LABEL_ADVISORCODE',
  COMMON_LABEL_ADVISORNAME: 'COMMON_LABEL_ADVISORNAME',
  COMMON_LABEL_MONTH: 'COMMON_LABEL_MONTH',
  COMMON_LABEL_YEAR: 'COMMON_LABEL_YEAR',
  COMMON_LABEL_PAR: 'COMMON_LABEL_PAR',
  COMMON_LABEL_ADJUSTMENT_TYPE: 'COMMON_LABEL_ADJUSTMENT_TYPE',
  COMMON_LABEL_APP_RECEIVED_DATE: 'COMMON_LABEL_APP_RECEIVED_DATE',
  COMMON_LABEL_BASIC_PLAN_CODE: 'COMMON_LABEL_BASIC_PLAN_CODE',
  COMMON_LABEL_BASIS_SCS: 'COMMON_LABEL_BASIS_SCS',
  COMMON_LABEL_BRANCH_CODE: 'COMMON_LABEL_BRANCH_CODE',
  COMMON_LABEL_CARD_CODE: 'COMMON_LABEL_CARD_CODE',
  COMMON_LABEL_CHARGEBACK_RATE: 'COMMON_LABEL_CHARGEBACK_RATE',
  COMMON_LABEL_CLIENT_NUMBER: 'COMMON_LABEL_CLIENT_NUMBER',
  COMMON_LABEL_COMMISSIONABLE_PERMIUM: 'COMMON_LABEL_COMMISSIONABLE_PERMIUM',
  COMMON_LABEL_COMPENSATION_HIERARCHY: 'COMMON_LABEL_COMPENSATION_HIERARCHY',
  COMMON_LABEL_COMPENSATION_STRUCTURE_LOOKUP: 'COMMON_LABEL_COMPENSATION_STRUCTURE_LOOKUP',
  COMMON_LABEL_COV_PLAN_RATE_SCALE: 'COMMON_LABEL_COV_PLAN_RATE_SCALE',
  COMMON_LABEL_COVERAGE: 'COMMON_LABEL_COVERAGE',
  COMMON_LABEL_COVERAGE_STATUS: 'COMMON_LABEL_COVERAGE_STATUS',
  COMMON_LABEL_CREATE_DATE: 'COMMON_LABEL_CREATE_DATE',
  COMMON_LABEL_CUMULATIVE_DEPOSIT: 'COMMON_LABEL_CUMULATIVE_DEPOSIT',
  COMMON_LABEL_CURRENT_COUNTRY: 'COMMON_LABEL_CURRENT_COUNTRY',
  COMMON_LABEL_CURRENT_LOCATION: 'COMMON_LABEL_CURRENT_LOCATION',
  COMMON_LABEL_DUE_DATE: 'COMMON_LABEL_DUE_DATE',
  COMMON_LABEL_DURATION: 'COMMON_LABEL_DURATION',
  COMMON_LABEL_EFFECTIVEDATE: 'COMMON_LABEL_EFFECTIVEDATE',
  COMMON_LABEL_DURATION_MONTHS: 'COMMON_LABEL_DURATION_MONTHS',
  COMMON_LABEL_EXP_SEQ_NUMBER: 'COMMON_LABEL_EXP_SEQ_NUMBER',
  COMMON_LABEL_FACE_AMOUNT: 'COMMON_LABEL_FACE_AMOUNT',
  COMMON_LABEL_FASAT_DIVISION: 'COMMON_LABEL_FASAT_DIVISION',
  COMMON_LABEL_FASAT_ELEMENT: 'COMMON_LABEL_FASAT_ELEMENT',
  COMMON_LABEL_ELEMENTS: 'COMMON_LABEL_ELEMENTS',
  COMMON_LABEL_FASAT_PRODUCT: 'COMMON_LABEL_FASAT_PRODUCT',
  COMMON_LABEL_FIRST_NAME: 'COMMON_LABEL_FIRST_NAME',
  COMMON_LABEL_FUND: 'COMMON_LABEL_FUND',
  COMMON_LABEL_FUND_COMPANY: 'COMMON_LABEL_FUND_COMPANY',
  COMMON_LABEL_FUND_NUMBER: 'COMMON_LABEL_FUND_NUMBER',
  COMMON_LABEL_GENERATION_ID: 'COMMON_LABEL_GENERATION_ID',
  COMMON_LABEL_GENERATION_SYSTEM: 'COMMON_LABEL_GENERATION_SYSTEM',
  COMMON_LABEL_GENERATION_TRANSACTION: 'COMMON_LABEL_GENERATION_TRANSACTION',
  COMMON_LABEL_GROSS_COMMISSION: 'COMMON_LABEL_GROSS_COMMISSION',
  COMMON_LABEL_INFORCE_DATE: 'COMMON_LABEL_INFORCE_DATE',
  COMMON_LABEL_ISSUE_COUNTRY: 'COMMON_LABEL_ISSUE_COUNTRY',
  COMMON_LABEL_ISSUE_LOCATION: 'COMMON_LABEL_ISSUE_LOCATION',
  COMMON_LABEL_JOINT_INDICATOR: 'COMMON_LABEL_JOINT_INDICATOR',
  COMMON_LABEL_LAST_NAME: 'COMMON_LABEL_LAST_NAME',
  COMMON_LABEL_LICENCE_VALIDATED: 'COMMON_LABEL_LICENCE_VALIDATED',
  COMMON_LABEL_MDRT_ELIGIBLE: 'COMMON_LABEL_MDRT_ELIGIBLE',
  COMMON_LABEL_MODE: 'COMMON_LABEL_MODE',
  COMMON_LABEL_ORIGINAL_ADVISOR: 'COMMON_LABEL_ORIGINAL_ADVISOR',
  COMMON_LABEL_ORIGINAL_AMOUNT: 'COMMON_LABEL_ORIGINAL_AMOUNT',
  COMMON_LABEL_ORIGINAL_CURRENCY: 'COMMON_LABEL_ORIGINAL_CURRENCY',
  COMMON_LABEL_OVERRIDE_COMMISSION_AMOUNT: 'COMMON_LABEL_OVERRIDE_COMMISSION_AMOUNT',
  COMMON_LABEL_PAR_CODE: 'COMMON_LABEL_PAR_CODE',
  COMMON_LABEL_PLAN_RATE_SCALE: 'COMMON_LABEL_PLAN_RATE_SCALE',
  COMMON_LABEL_POLICY: 'COMMON_LABEL_POLICY',
  COMMON_LABEL_PREMIUM_NUMBER: 'COMMON_LABEL_PREMIUM_NUMBER',
  COMMON_LABEL_PROCESSED_DATE: 'COMMON_LABEL_PROCESSED_DATE',
  COMMON_LABEL_RATE_BASIS: 'COMMON_LABEL_RATE_BASIS',
  COMMON_LABEL_RATE_DETAIL_ID: 'COMMON_LABEL_RATE_DETAIL_ID',
  COMMON_LABEL_RATE_DURATION: 'COMMON_LABEL_RATE_DURATION',
  COMMON_LABEL_RATE_LOOKUP: 'COMMON_LABEL_RATE_LOOKUP',
  COMMON_LABEL_RATE_MASTER_ID: 'COMMON_LABEL_RATE_MASTER_ID',
  COMMON_LABEL_RATE_TERM: 'COMMON_LABEL_RATE_TERM',
  COMMON_LABEL_REMARK: 'COMMON_LABEL_REMARK',
  COMMON_LABEL_SALES_CREDIT: 'COMMON_LABEL_SALES_CREDIT',
  COMMON_LABEL_SECURITY_NUMBER: 'COMMON_LABEL_SECURITY_NUMBER',
  COMMON_LABEL_SPLIT_RATE: 'COMMON_LABEL_SPLIT_RATE',
  COMMON_LABEL_STAT_ACCT: 'COMMON_LABEL_STAT_ACCT',
  COMMON_LABEL_STMT_BASIS_AMOUNT: 'COMMON_LABEL_STMT_BASIS_AMOUNT',
  COMMON_LABEL_SUB_ACCOUNT: 'COMMON_LABEL_SUB_ACCOUNT',
  COMMON_LABEL_TAXABLE_EARNING: 'COMMON_LABEL_TAXABLE_EARNING',
  COMMON_LABEL_TRADE_AMOUNT: 'COMMON_LABEL_TRADE_AMOUNT',
  COMMON_LABEL_TRADE_NUMBER: 'COMMON_LABEL_TRADE_NUMBER',
  COMMON_LABEL_VARIABLE_CODE: 'COMMON_LABEL_VARIABLE_CODE',
  COMMON_LABEL_COMPANY_CODE: 'COMMON_LABEL_COMPANY_CODE',
  COMMON_BUTTON_REPROCESS: 'COMMON_BUTTON_REPROCESS',
  COMMON_BUTTON_REVERSE: 'COMMON_BUTTON_REVERSE',
  COMMIN_LABEL_STATUS: 'COMMIN_LABEL_STATUS',
  COMMON_LABEL_PATH: 'COMMON_LABEL_PATH',
  COMMON_BUTTON_PROCESS_NOW: 'COMMON_BUTTON_PROCESS_NOW',
  COMMON_LABEL_BATCH_NUMBER: 'COMMON_LABEL_BATCH_NUMBER',
  COMMON_LABEL_COMPENSATION_DATE: 'COMMON_LABEL_COMPENSATION_DATE',
  COMMON_LABEL_COVERAGE_PLAN_CODE: 'COMMON_LABEL_COVERAGE_PLAN_CODE',
  COMMON_LABEL_OVRD_ADVISOR_POINTER: 'COMMON_LABEL_OVRD_ADVISOR_POINTER',
  COMMON_ACTIVITY_PROCESS_ERROR: 'COMMON_ACTIVITY_PROCESS_ERROR',
  COMMON_ACTIVITY_DELETE_ERROR: 'COMMON_ACTIVITY_DELETE_ERROR',
  COMMON_TOTAL_RECORDS_SELECTED: 'COMMON_TOTAL_RECORDS_SELECTED',
  COMMON_TOTAL_RECORDS_PROCESSED: 'COMMON_TOTAL_RECORDS_PROCESSED',
  COMMON_TOTAL_RECORDS_DELETED: 'COMMON_TOTAL_RECORDS_DELETED',
  COMMON_BATCHNUM_AND_REASON: 'COMMON_BATCHNUM_AND_REASON',
  COMMON_ACTIVITY_PROCESS_SUCCESS: 'COMMON_ACTIVITY_PROCESS_SUCCESS',
  COMMON_SUCCESS_MESSAGE_AP_AUP: 'COMMON_SUCCESS_MESSAGE_AP_AUP',
  COMMON_LABEL_FROM_TO_ADVISOR: 'COMMON_LABEL_FROM_TO_ADVISOR',
  COMMON_LABEL_FORM_TO_ADV: 'COMMON_LABEL_FORM_TO_ADV',
  COMMON_LABEL_TEMPLATE: 'COMMON_LABEL_TEMPLATE',
  COMMON_LABEL_CATEGORY: 'COMMON_LABEL_CATEGORY',
  COMMON_LABEL_CREATEDBY: 'COMMON_LABEL_CREATEDBY',
  COMMON_LABEL_PRIVATE: 'COMMON_LABEL_PRIVATE',
  COMMON_LABEL_SCHEDULE_STATUS: 'COMMON_LABEL_SCHEDULE_STATUS',
  COMMON_LABEL_SHARED_ON: 'COMMON_LABEL_SHARED_ON',
  COMMON_BUTTON_GENERATE_REPORT: 'COMMON_BUTTON_GENERATE_REPORT',
  COMMON_LABEL_INACTIVE: 'COMMON_LABEL_INACTIVE',
  COMMON_LABEL_REPORT_FREQUENCY: 'COMMON_LABEL_REPORT_FREQUENCY',
  COMMON_LABEL_GROUP: 'COMMON_LABEL_GROUP',
  COMMON_TOTAL_RECORDS_ELIGIBLE: 'COMMON_TOTAL_RECORDS_ELIGIBLE',
  COMMON_LABEL_CHARGEBACK_RATE_METHOD: 'COMMON_LABEL_CHARGEBACK_RATE_METHOD',
  COMMON_LABEL_CHARGEBACK_RATE_BASIS: 'COMMON_LABEL_CHARGEBACK_RATE_BASIS',
  COMMON_LABEL_RATE_METHOD: 'COMMON_LABEL_RATE_METHOD',
  COMMON_LABEL_RATE_PERCENTAGE: 'COMMON_LABEL_RATE_PERCENTAGE',
  COMMON_ACTIVITY_PROCESS_ELIGIBLE_CONFIRMATION: 'COMMON_ACTIVITY_PROCESS_ELIGIBLE_CONFIRMATION',
  COMMON_BUTTON_RESUME: 'COMMON_BUTTON_RESUME',
  COMMON_LABEL_MSC_TRANSFER: 'COMMON_LABEL_MSC_TRANSFER',
  COMMON_LABEL_LASTNAME: 'COMMON_LABEL_LASTNAME',
  COMMON_LABEL_APPR_RECEIVED_DATE: 'COMMON_LABEL_APPR_RECEIVED_DATE',
  COMMON_LABEL_COVR_PLAN_RATE_SCALE: 'COMMON_LABEL_COVR_PLAN_RATE_SCALE',
  COMMON_LABEL_AFYC: 'COMMON_LABEL_AFYC',
  COMMON_BUTTON_BULK_REPROCESS: 'COMMON_BUTTON_BULK_REPROCESS',
  COMMON_LABEL_ACTIVITY_NAME: 'COMMON_LABEL_ACTIVITY_NAME',
  COMMON_LABEL_PRODUCT_GROUP: 'COMMON_LABEL_PRODUCT_GROUP',
  COMMON_LABEL_SCE_FOR_PERIOD: 'COMMON_LABEL_SCE_FOR_PERIOD',
  COMMON_LABEL_IAR_TAB_CONTENT: 'COMMON_LABEL_IAR_TAB_CONTENT',
  COMMON_LABEL_IAR_TITLE: 'COMMON_LABEL_IAR_TITLE',
  COMMON_LABEL_PQL_CONTENT: 'COMMON_LABEL_PQL_CONTENT',
  COMMON_LABEL_PQL_US_CONTENT: 'COMMON_LABEL_PQL_US_CONTENT',
  /**
     * Common MSG_ID to be used across pages - END
     */

  /*
     *AODA COMMON LABELS AND AODA FILTER SETTINGS LABEL - START
     */
  ARIA_COMMON_DROPDOWN_SEARCH_LABEL: 'ARIA_COMMON_DROPDOWN_SEARCH_LABEL',
  ARIA_COMMON_DROPDOWN_SEARCH_RESULT: 'ARIA_COMMON_DROPDOWN_SEARCH_RESULT',
  ARIA_COMMON_COLUMN_ORDER_BUTTON_RIGHT: 'ARIA_COMMON_COLUMN_ORDER_BUTTON_RIGHT',
  ARIA_COMMON_COLUMN_ORDER_BUTTON_LEFT: 'ARIA_COMMON_COLUMN_ORDER_BUTTON_LEFT',
  ARIA_COMMON_COLUMN_ORDER_BUTTON_TOP: 'ARIA_COMMON_COLUMN_ORDER_BUTTON_TOP',
  ARIA_COMMON_COLUMN_ORDER_BUTTON_UP: 'ARIA_COMMON_COLUMN_ORDER_BUTTON_UP',
  ARIA_COMMON_COLUMN_ORDER_BUTTON_DOWN: 'ARIA_COMMON_COLUMN_ORDER_BUTTON_DOWN',
  ARIA_COMMON_COLUMN_ORDER_BUTTON_BOTTOM: 'ARIA_COMMON_COLUMN_ORDER_BUTTON_BOTTOM',
  ARIA_COMMON_BUTTON_EXPORT_LIST: 'ARIA_COMMON_BUTTON_EXPORT_LIST',
  ARIA_COMMON_GRID_BUTTON_EDIT: 'ARIA_COMMON_GRID_BUTTON_EDIT',
  ARIA_COMMON_GRID_BUTTON_VIEW: 'ARIA_COMMON_GRID_BUTTON_VIEW',
  ARIA_COMMON_GRID_BUTTON_CLONE: 'ARIA_COMMON_GRID_BUTTON_CLONE',
  ARIA_COMMON_GRID_BUTTON_DELETE: 'ARIA_COMMON_GRID_BUTTON_DELETE',
  ARIA_COMMON_PAGINATION_BUTTON_PREVIOUS: 'ARIA_COMMON_PAGINATION_BUTTON_PREVIOUS',
  ARIA_COMMON_PAGINATION_BUTTON_NEXT: 'ARIA_COMMON_PAGINATION_BUTTON_NEXT',
  ARIA_COMMON_ACTION_BUTTON_ADD: 'ARIA_COMMON_ACTION_BUTTON_ADD',
  ARIA_COMMON_PAGINATION_INPUT_LABEL: 'ARIA_COMMON_PAGINATION_INPUT_LABEL',
  ARIA_COMMON_GRID_BUTTON_LOAD: 'ARIA_COMMON_GRID_BUTTON_LOAD',

  /* FILTER SETTINGS AODA */
  ARIA_FILTER_BUTTON_ADD_FILTER_CRITERIA: 'ARIA_FILTER_BUTTON_ADD_FILTER_CRITERIA',
  ARIA_FILTER_BUTTON_DELETE_FILTER_CRITERIA: 'ARIA_FILTER_BUTTON_DELETE_FILTER_CRITERIA',
  ARIA_FILTER_BUTTON_ADD_SORT_ORDER: 'ARIA_FILTER_BUTTON_ADD_SORT_ORDER',
  ARIA_FILTER_BUTTON_ADD_GROUP_BY: 'ARIA_FILTER_BUTTON_ADD_GROUP_BY',
  ARIA_FILTER_BUTTON_DELETE_GROUP_BY: 'ARIA_FILTER_BUTTON_DELETE_GROUP_BY',
  ARIA_FILTER_BUTTON_DELETE_SORT_ORDER: 'ARIA_FILTER_BUTTON_DELETE_SORT_ORDER',
  /*
     *AODA COMMON LABELS AND AODA FILTER SETTINGS LABEL - END
     */
  ARIA_COMMON_BUTTON_DOWNLOAD: 'ARIA_COMMON_BUTTON_DOWNLOAD',
  // ui concurrency batch processing
  BATCH_STATUS_INPROGRESS: 'BATCH_STATUS_INPROGRESS',
  BATCH_INPROGRESS_WEB_STATUS_MSG: 'BATCH_INPROGRESS_WEB_STATUS_MSG',
  COMMON_LABEL_MLC_SELLING_CD: 'COMMON_LABEL_MLC_SELLING_CD',
  COMMON_LABEL_MLC_SECCODE: 'COMMON_LABEL_MLC_SECCODE',
  COMMON_LABEL_BUSINESS_FIRSTNAME: 'COMMON_LABEL_BUSINESS_FIRSTNAME',
  COMMON_LABEL_BUSINESS_LASTNAME: 'COMMON_LABEL_BUSINESS_LASTNAME',
  COMMON_LABEL_BUSINESS_SURNAME: 'COMMON_LABEL_BUSINESS_SURNAME',
  COMMON_LABEL_SPONSORED: 'COMMON_LABEL_SPONSORED',
  COMMON_LABEL_SEQUENCE: 'COMMON_LABEL_SEQUENCE',
  COMMON_LABEL_FIELD_FORCE_TYPE: 'COMMON_LABEL_FIELD_FORCE_TYPE',
  COMMON_LABEL_FF_STATUS: 'COMMON_LABEL_FF_STATUS',
  COMMON_LABEL_CONTRACT_DATE: 'COMMON_LABEL_CONTRACT_DATE',
  COMMON_LABEL_TERMINATION_DATE: 'COMMON_LABEL_TERMINATION_DATE',
  COMMON_LABEL_ACTIVERECORD: 'COMMON_LABEL_ACTIVERECORD',
  COMMON_LABEL_EFF_DATE: 'COMMON_LABEL_EFF_DATE',
  COMMON_LABEL_MOVEMENT_DATE: 'COMMON_LABEL_MOVEMENT_DATE',
  COMMON_LABEL_CB_DEBIT_START_DATE: 'COMMON_LABEL_CB_DEBIT_START_DATE',
  COMMON_LABEL_FYC: 'COMMON_LABEL_FYC',
  COMMON_LABEL_FYCYTD: 'COMMON_LABEL_FYCYTD',
  COMMON_LABEL_FYO: 'COMMON_LABEL_FYO',
  COMMON_LABEL_FYOYTD: 'COMMON_LABEL_FYOYTD',
  COMMON_LABEL_POLICY_COUNT: 'COMMON_LABEL_POLICY_COUNT',
  COMMON_LABEL_POLICY_COUNT_YTD: 'COMMON_LABEL_POLICY_COUNT_YTD',
  COMMON_LABEL_REN_COMM: 'COMMON_LABEL_REN_COMM',
  COMMON_LABEL_REN_COMM_YTD: 'COMMON_LABEL_REN_COMM_YTD',
  COMMON_LABEL_REN_OVERRIDE: 'COMMON_LABEL_REN_OVERRIDE',
  COMMON_LABEL_REN_OV_YTD: 'COMMON_LABEL_REN_OV_YTD',
  COMMON_LABEL_REFERRAL_FEE: 'COMMON_LABEL_REFERRAL_FEE',
  COMMON_LABEL_TOTAL_COMP: 'COMMON_LABEL_TOTAL_COMP',
  COMMON_LABEL_MISC_ADJ: 'COMMON_LABEL_MISC_ADJ',
  COMMON_LABEL_MISC_ADJUSTMENTS: 'COMMON_LABEL_MISC_ADJUSTMENTS',
  COMMON_LABEL_MISC_ADJ_YTD: 'COMMON_LABEL_MISC_ADJ_YTD',
  COMMON_LABEL_RESV_CAP: 'COMMON_LABEL_RESV_CAP',
  COMMON_LABEL_NEG_DEBT_TRANSFRD: 'COMMON_LABEL_NEG_DEBT_TRANSFRD',
  COMMON_LABEL_NEG_DEBIT_START_DATE: 'COMMON_LABEL_NEG_DEBIT_START_DATE',
  COMMON_LABEL_BAL_FWD: 'COMMON_LABEL_BAL_FWD',
  COMMON_LABEL_BAL_FWD_YTD: 'COMMON_LABEL_BAL_FWD_YTD',
  COMMON_LABEL_CURR_BAL: 'COMMON_LABEL_CURR_BAL',
  COMMON_LABEL_CURR_BAL_YTD: 'COMMON_LABEL_CURR_BAL_YTD',
  COMMON_LABEL_RESV_CAP_FWD: 'COMMON_LABEL_RESV_CAP_FWD',
  COMMON_LABEL_CAPP_ACC: 'COMMON_LABEL_CAPP_ACC',
  COMMON_LABEL_CAPP_FORWARD: 'COMMON_LABEL_CAPP_FORWARD',
  COMMON_LABEL_RES_CAP_BAL: 'COMMON_LABEL_RES_CAP_BAL',
  COMMON_LABEL_RESV_CAP_BAL: 'COMMON_LABEL_RESV_CAP_BAL',
  COMMON_LABEL_CAPP_BALANCE: 'COMMON_LABEL_CAPP_BALANCE',
  COMMON_LABEL_RD_FWD: 'COMMON_LABEL_RD_FWD',
  COMMON_LABEL_RD_FWD_YTD: 'COMMON_LABEL_RD_FWD_YTD',
  COMMON_LABEL_RD_BAL: 'COMMON_LABEL_RD_BAL',
  COMMON_LABEL_RD_BAL_YTD: 'COMMON_LABEL_RD_BAL_YTD',
  COMMON_LABEL_TAXABLE_BENEFITS: 'COMMON_LABEL_TAXABLE_BENEFITS',
  COMMON_LABEL_TAXABLE_BENEFITS_YTD: 'COMMON_LABEL_TAXABLE_BENEFITS_YTD',
  COMMON_LABEL_MDRT_FYC: 'COMMON_LABEL_MDRT_FYC',
  COMMON_LABEL_NEG_DEBIT_FWD: 'COMMON_LABEL_NEG_DEBIT_FWD',
  COMMON_LABEL_RD_TRANSFRD: 'COMMON_LABEL_RD_TRANSFRD',
  COMMON_LABEL_RD_TRANSFRD_YTD: 'COMMON_LABEL_RD_TRANSFRD_YTD',
  COMMON_LABEL_REFERRAL_FEE_YTD: 'COMMON_LABEL_REFERRAL_FEE_YTD',
  COMMON_LABEL_EQUALIZED_FY: 'COMMON_LABEL_EQUALIZED_FY',
  COMMON_LABEL_EQUALIZED_FY_YTD: 'COMMON_LABEL_EQUALIZED_FY_YTD',
  COMMON_LABEL_EQUALIZED_OVERRIDE: 'COMMON_LABEL_EQUALIZED_OVERRIDE',
  COMMON_LABEL_EQUALIZED_OVERRIDE_YTD: 'COMMON_LABEL_EQUALIZED_OVERRIDE_YTD',
  COMMON_LABEL_PERIOD: 'COMMON_LABEL_PERIOD',
  COMMON_LABEL_NO_RECORD_FOUND: 'COMMON_LABEL_NO_RECORD_FOUND',
  COMMON_LABEL_FROM: 'COMMON_LABEL_FROM',
  COMMON_LABEL_TO: 'COMMON_LABEL_TO',
  COMMON_BUTTON_TRANSFER: 'COMMON_BUTTON_TRANSFER',
  COMMON_DEL_CONFRMSG: 'COMMON_DEL_CONFRMSG',
  COMMON_DEL_SUCCESS: 'COMMON_DEL_CONFRMSG',
  COMMON_LABEL_MONTHLY: 'COMMON_LABEL_MONTHLY',
  COMMON_LABEL_SUMMARY: 'COMMON_LABEL_SUMMARY',
  COMMON_LABEL_HIERARCHY: 'COMMON_LABEL_HIERARCHY',
  COMMON_LABEL_PREV_YEAR: 'COMMON_LABEL_PREV_YEAR',
  COMMON_LABEL_DATE: 'COMMON_LABEL_DATE',
  COMMON_LABEL_ACTIVE_BAL: 'COMMON_LABEL_ACTIVE_BAL',
  COMMON_LABEL_VIEW_BY: 'COMMON_LABEL_VIEW_BY',
  COMMON_LABEL_TERM: 'COMMON_LABEL_TERM',
  COMMON_LABEL_FYR_COMM: 'COMMON_LABEL_FYR_COMM',
  COMMON_LABEL_EQ_FYR_COMM: 'COMMON_LABEL_EQ_FYR_COMM',
  COMMON_LABEL_FYR_OV: 'COMMON_LABEL_FYR_OV',
  COMMON_LABEL_EQ_FYR_OV: 'COMMON_LABEL_EQ_FYR_OV',
  COMMON_LABEL_REN_OV: 'COMMON_LABEL_REN_OV',
  COMMON_LABEL_DEB_BAL: 'COMMON_LABEL_DEB_BAL',
  COMMON_LABEL_CURR_YEAR_MONTH: 'COMMON_LABEL_CURR_YEAR_MONTH',
  COMMON_LABEL_CURR_YEAR_YTD: 'COMMON_LABEL_CURR_YEAR_YTD',
  COMMON_LABEL_PREV_YEAR_MONTH: 'COMMON_LABEL_PREV_YEAR_MONTH',
  COMMON_LABEL_PREV_YEAR_YTD: 'COMMON_LABEL_PREV_YEAR_YTD',
  COMMON_LABEL_AYFC: 'COMMON_LABEL_AYFC',
  COMMON_LABEL_CHARGEBACKS: 'COMMON_LABEL_CHARGEBACKS',
  COMMON_LABEL_NAFYC: 'COMMON_LABEL_NAFYC',
  COMMON_LABEL_EQ_FIRST_YR_COMM: 'COMMON_LABEL_EQ_FIRST_YR_COMM',
  COMMON_LABEL_RENEWAL_COMM: 'COMMON_LABEL_RENEWAL_COMM',
  COMMON_LABEL_AFYO: 'COMMON_LABEL_AFYO',
  COMMON_LABEL_CHARGEBACK: 'COMMON_LABEL_CHARGEBACK',
  COMMON_LABEL_NAYFO: 'COMMON_LABEL_NAYFO',
  COMMON_LABEL_NAFYO: 'COMMON_LABEL_NAFYO',
  COMMON_LABEL_REFERENCE_GROSS_COMM: 'COMMON_LABEL_REFERENCE_GROSS_COMM',
  COMMON_LABEL_FYC_COMM_PREMIUM: 'COMMON_LABEL_FYC_COMM_PREMIUM',
  COMMON_LABEL_REN_COMM_PREMIUM: 'COMMON_LABEL_REN_COMM_PREMIUM',
  COMMON_LABEL_REN_GROSS_PREMIUM: 'COMMON_LABEL_REN_GROSS_PREMIUM',
  COMMON_LABEL_REN_GROSS_COMMISSION: 'COMMON_LABEL_REN_GROSS_COMMISSION',
  COMMON_LABEL_REF_GROSS_COMMISSION: 'COMMON_LABEL_REF_GROSS_COMMISSION',
  COMMON_LABEL_CURR_YEAR_MONTHLY: 'COMMON_LABEL_CURR_YEAR_MONTHLY',
  COMMON_LABEL_SURNAME: 'COMMON_LABEL_SURNAME',
  COMMON_LABEL_FIRSTNAME: 'COMMON_LABEL_FIRSTNAME',
  COMMON_LABEL_VIEW: 'TA_LINK_VIEW',
  COMMON_LABEL_JANUARY: 'DP_LABEL_JANUARY',
  COMMON_LABEL_FEBRUARY: 'DP_LABEL_FEBRUARY',
  COMMON_LABEL_MARCH: 'DP_LABEL_MARCH',
  COMMON_LABEL_APRIL: 'DP_LABEL_APRIL',
  COMMON_LABEL_MAY: 'DP_LABEL_MAY',
  COMMON_LABEL_JUNE: 'DP_LABEL_JUNE',
  COMMON_LABEL_JULY: 'DP_LABEL_JULY',
  COMMON_LABEL_AUGUST: 'DP_LABEL_AUGUST',
  COMMON_LABEL_SEPTEMBER: 'DP_LABEL_SEPTEMBER',
  COMMON_LABEL_OCTOBER: 'DP_LABEL_OCTOBER',
  COMMON_LABEL_NOVEMBER: 'DP_LABEL_NOVEMBER',
  COMMON_LABEL_DECEMBER: 'DP_LABEL_DECEMBER',
  COMMON_LABEL_BUSS_VIEW: 'COMMON_LABEL_BUSS_VIEW',
  COMMON_LABEL_COMBINED: 'COMMON_LABEL_COMBINED',
  COMMON_LABEL_YTD: 'COMMON_LABEL_YTD',
  COMMON_LABEL_PRODUCTION: 'COMMON_LABEL_PRODUCTION',
  COMMON_LABEL_BALANCE: 'PAY_LABEL_BALANCE',
  COMMON_LABEL_RATE_TYPE: 'COMMON_LABEL_RATE_TYPE',
  COMMON_LABEL_PLAN_CODE: 'COMMON_LABEL_PLAN_CODE',
  COMMON_LABEL_RATE_SCALE: 'COMMON_LABEL_RATE_SCALE',
  COMMON_LABEL_ELEMENT: 'COMMON_LABEL_ELEMENT',
  COMMON_LABEL_ADVISOR: 'COMMON_LABEL_ADVISOR',
  COMMON_LABEL_DISTRIBUTORS: 'COMMON_LABEL_DISTRIBUTORS',
  COMMON_LABEL_YEARS: 'COMMON_LABEL_YEARS',
  COMMON_LABEL_MONTHS: 'COMMON_LABEL_MONTHS',
  COMMON_LABEL_SALES_CREDITS: 'COMMON_LABEL_SALES_CREDITS',
  COMMON_LABEL_PAYEE: 'COMMON_LABEL_PAYEE',
  COMMON_LABEL_GICTERM_BAND: 'COMMON_LABEL_GICTERM_BAND',
  COMMON_LABEL_START_DATETIME: 'COMMON_LABEL_START_DATETIME',
  COMMON_LABEL_END_DATETIME: 'COMMON_LABEL_END_DATETIME',
  COMMON_LABEL_CLEAR_RESULTS: 'COMMON_LABEL_CLEAR_RESULTS',
  COMMON_LABEL_DATEFORMAT: 'COMMON_LABEL_DATEFORMAT',
  COMMON_LABEL_DATEFORMAT_ET: 'COMMON_LABEL_DATEFORMAT_ET',
  COMMON_LABEL_ERROR_DESC: 'COMMON_LABEL_ERROR_DESC',
  COMMON_BUTTON_DOWNLOAD: 'COMMON_BUTTON_DOWNLOAD',
  COMMON_LABEL_APPLICATION: 'COMMON_LABEL_APPLICATION',
  COMMON_LABEL_MEMO: 'COMMON_LABEL_MEMO',
  COMMON_LABEL_IDENTIFIER: 'COMMON_LABEL_IDENTIFIER',
  COMMON_LABEL_POLICY_NUM: 'COMMON_LABEL_POLICY_NUM',
  COMMON_LABEL_SEQ_NUM: 'COMMON_LABEL_SEQ_NUM',
  COMMON_LABEL_FASAT_LOB: 'COMMON_LABEL_FASAT_LOB',
  COMMON_LABEL_GENERATION_DATE: 'COMMON_LABEL_GENERATION_DATE',
  COMMON_LABEL_INDID_FROM_TO_ADVISOR: 'COMMON_LABEL_INDID_FROM_TO_ADVISOR',
  COMMON_LABEL_DETAILS: 'COMMON_LABEL_DETAILS',
  COMMON_LABEL_NUMBER: 'COMMON_LABEL_NUMBER',
  COMMON_LABEL_BASIC_PLAN: 'COMMON_LABEL_BASIC_PLAN',
  COMMON_LABEL_MDRT: 'COMMON_LABEL_MDRT',
  COMMON_BUTTON_APPROVE: 'COMMON_BUTTON_APPROVE',
  COMMON_LABEL_CHARGE: 'COMMON_LABEL_CHARGE',
  COMMON_LABEL_CREDIT: 'COMMON_LABEL_CREDIT',
  COMMON_LABEL_JOB_REQUEST_ID: 'COMMON_LABEL_JOB_REQUEST_ID',
  COMMON_LABEL_ERROR_FLAG: 'COMMON_LABEL_ERROR_FLAG',

  // External started here
  COMMON_LABEL_ADVISOR_DETAILS: 'COMMON_LABEL_ADVISOR_DETAILS',
  COMMON_LABEL_DISTRIBUTOR_DETAILS: 'COMMON_LABEL_DISTRIBUTOR_DETAILS',
  COMMON_LABEL_NO_STATEMENT: 'COMMON_LABEL_NO_STATEMENT',
  COMMON_LABEL_POl_HOLD_NAME: 'COMMON_LABEL_POl_HOLD_NAME',
  COMMON_LABEL_PLAN_NAME: 'COMMON_LABEL_PLAN_NAME',
  COMMON_LABEL_COMM_SUB: 'COMMON_LABEL_COMM_SUB',
  COMMON_LABEL_COMP_AMNT: 'COMMON_LABEL_COMP_AMNT',
  COMMON_LABEL_FORM_ADV_NAME: 'COMMON_LABEL_FORM_ADV_NAME',
  COMMON_LABEL_SEARCHBY: 'COMMON_LABEL_SEARCHBY',
  COMMON_LABEL_POLICY_OR_ACCOUNT_NUM: 'COMMON_LABEL_POLICY_OR_ACCOUNT_NUM',
  COMMON_LABEL_GRAND_TOTAL: 'COMMON_LABEL_GRAND_TOTAL',
  COMMON_LABEL_SUB_TOTAL: 'COMMON_LABEL_SUB_TOTAL',
  COMMON_LABEL_SEARCH_OPTIONS: 'COMMON_LABEL_SEARCH_OPTIONS',
  COMMON_LABEL_QUICK_SEARCH: 'COMMON_LABEL_QUICK_SEARCH',
  COMMON_LABEL_ADV_SEARCH: 'COMMON_LABEL_ADV_SEARCH',
  COMMON_LABEL_LAST_OR_COMPANY_NAME: 'COMMON_LABEL_LAST_OR_COMPANY_NAME',
  COMMON_LABEL_AP_IN_LAST: 'COMMON_LABEL_AP_IN_LAST',
  COMMON_LABEL_RECOM_TGD_IN_FCFF_RESULTS: 'COMMON_LABEL_RECOM_TGD_IN_FCFF_RESULTS',
  COMMON_LABEL_USER_PREF_LANG_TITLE: 'COMMON_LABEL_USER_PREF_LANG_TITLE',
  COMMON_LABEL_USER_PREF_LANG_SUCC_MSG: 'COMMON_LABEL_USER_PREF_LANG_SUCC_MSG',
  COMMON_LABEL_BATCH_PROCESSING_MSG: 'COMMON_LABEL_BATCH_PROCESSING_MSG',

  //
  CIAM_LOGOUT_DEFAULT: 'CIAM_LOGOUT_DEFAULT',
  CIAM_LOGOUT_DEFAULT_WARNING: 'CIAM_LOGOUT_DEFAULT_WARNING',
  CIAM_LOGOUT_SESSION_INACTIVITY: 'CIAM_LOGOUT_SESSION_INACTIVITY',
  CIAM_LOGOUT_DEFAULT_WARNING_AUTOSIGNOUT: 'CIAM_LOGOUT_DEFAULT_WARNING_AUTOSIGNOUT',
  CIAM_LOGOUT_TWELVE_HOUR_EXPIRE: 'CIAM_LOGOUT_TWELVE_HOUR_EXPIRE',
  CIAM_LOGOUT_DEFAULT_WARNING_SAVEWORK: 'CIAM_LOGOUT_DEFAULT_WARNING_SAVEWORK',
  CIAM_SIGN_IN: 'CIAM_SIGN_IN',
  CIAM_SIGN_OUT: 'CIAM_SIGN_OUT',
  //
  COMMON_PREFERENCE_LINKS_LABEL: 'COMMON_PREFERENCE_LINKS_LABEL',
};

export default staticCommonLabel;
